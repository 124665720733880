import { RequestService } from "@/api/instance";
// import { DateTime } from "luxon";
import { DeAuthSession } from "@/api/de-auth";

export const GetRequests = async () => {
  try {
    let {
      data: { enquiries }
    } = await RequestService().get("/enquiries");

    return enquiries;
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};
