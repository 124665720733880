<template>
  <v-card class="requests-card" elevation="5">
    <v-toolbar dark color="primary">
      <v-toolbar-title class="title">Your Requests</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="lightGrey" href="/home/form/" class="request-btn">
        <span class="desktop">New Care Request</span>
        <v-icon class="mobile">
          add
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="main">
      <v-list two-line subheader v-if="requests.length">
        <v-list-item
          v-for="(request, index) in requests"
          :key="request.Enquiry.id"
          class="request"
          @click="
            open(
              request.Enquiry.id,
              request.accepted,
              request.acceptedProposalID
            )
          "
        >
          <v-list-item-content>
            <v-list-item-title v-if="!request.accepted"
              >Request - {{ (index - requests.length) * -1 }}
            </v-list-item-title>
            <v-list-item-title v-else>{{
              request.companyName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              request.created_at
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer class="desktop"></v-spacer>

          <v-chip-group column class="chips mobile">
            <v-avatar
              v-if="request.accepted"
              color="primary"
              size="32"
              class="ma-1"
            >
              <v-icon dense dark>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            <v-avatar
              color="lightGrey"
              size="32"
              class="ma-1"
              v-if="!request.accepted"
              >{{ request.responses }}
            </v-avatar>
          </v-chip-group>
          <v-chip-group column class="chips desktop">
            <v-chip v-if="request.accepted" color="primary"
              ><v-avatar left>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
              </v-avatar>
              Accepted</v-chip
            >
            <v-chip v-if="!request.accepted">
              Responses
              <v-avatar right color="lightGrey"
                >{{ request.Proposals.length }}
              </v-avatar>
            </v-chip>
          </v-chip-group>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
// import { BASE_URL } from '@/config'
// import axios from 'axios'
import { DateTime } from "luxon";
import { GetRequests } from "@/api/requests/requests";

export default {
  data() {
    return {
      deleteRequestModal: false,
      idToDelete: 0,
      requests: [],
      search: "",
      cqcFilterChoice: []
    };
  },
  async beforeMount() {
    let requests = await GetRequests();
    for (let r of requests) {
      r.Enquiry.created_at = DateTime.fromISO(
        r.Enquiry.created_at
      ).toLocaleString(DateTime.DATETIME_FULL);
      for (let p of r.Proposals) {
        if (p.accepted === true) {
          r.accepted = true;
          r.companyName = p.company_name;
          r.acceptedProposalID = p.proposal_id;
        }
      }
    }
    this.requests = requests;
  },
  computed: {
    requestsWithChat() {
      let requests = this.requests;

      return requests;
    }
  },
  methods: {
    open(id, accepted, responseID) {
      let route = "";
      if (accepted === true) {
        id = responseID;
        route = "response";
      } else {
        route = "responses";
      }
      this.$router.push({ name: route, params: { id } });
    },
    openDeleteRequestModal(id) {
      this.idToDelete = id;
      this.deleteRequestModal = true;
    }
  }
};
</script>

<style scoped>
.main {
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
}
.title {
  font-size: 1.5rem !important;
}
.list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
::v-deep .v-list-item__title {
  font-size: 1.3rem;
  color: var(--v-grey-darken2) !important;
}
::v-deep .v-list-item__subtitle {
  font-size: 1rem;
}
.body {
  display: flex;
}
.requests-card {
  max-width: 1200px;
  margin: 50px auto;
}
.request {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
::v-deep .v-btn__content {
  font-size: 18px;
}
.request-btn {
  color: var(--v-grey-darken2) !important;
}
.chips {
  max-width: 50%;
}
.desktop {
  display: inline;
}
.mobile {
  display: none;
}

@media only screen and (max-height: 768px) {
  .requests-card {
    max-width: 1050px;
    margin: 0 auto;
  }
  .main {
    max-height: 450px;
  }
}

@media only screen and (max-width: 767.98px) {
  .requests-card {
    max-width: 950px;
    margin: 0 auto;
  }
  .main {
    max-height: 500px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: inline;
  }
}
</style>
